@import url("https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&family=Open+Sans&display=swap");
.hero-section .hero-content .scroll-bottom, .feature-section .single-feature .icon, .box-style .icon, .testimonial-section .testimonial-active-wrapper .tns-controls button, .testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .testimonial-img .quote, .footer .widget-wrapper .footer-widget .socials li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/*===========================
    01.COMMON css 
===========================*/
html {
  scroll-behavior: smooth;
}

body {
  font-family: Arimo;
  font-weight: normal;
  font-style: normal;
  color: #5B657E;
  overflow-x: hidden;
}


a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

a:hover {
  color: #5864FF;
}

a {
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Arimo;
  font-weight: 700;
  margin: 0px;
  color: #162447;
}

.section-title p {
  font-size: 18px;
  line-height: 28px;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .section-title h2 {
    font-size: 40px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2 {
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2 {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .section-title h2 {
    font-size: 28px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .section-title h2 {
    font-size: 32px;
  }
}

.section-title span {
  font-size: 25px;
  color: #162447;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 767px) {
  .section-title span {
    font-size: 18px;
  }
}

.section-title span img {
  max-width: 100%;
  margin-right: 20px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 25px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}



.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.error {
  color: orangered;
}

.success {
  color: green;
}

@media (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-btn {
  display: inline-block;
  font-weight: 700;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 18px 38px;
  font-size: 18px;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  border: none;
  background: #5864FF;
  overflow: hidden;
}

.main-btn:hover {
  color: #fff;
}

.main-btn.border-btn {
  border: 2px solid #5864FF;
  background: transparent;
  color: #5864FF;
}

.btn-hover {
  position: relative;
  overflow: hidden;
}

.btn-hover::after {
  content: "";
  position: absolute;
  width: 0%;
  height: 0%;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  top: 50%;
  left: 50%;
  padding: 50%;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translate3d(-50%, -50%, 0) scale(0);
  transform: translate3d(-50%, -50%, 0) scale(0);
}

.btn-hover:hover::after {
  -webkit-transform: translate3d(-50%, -50%, 0) scale(1.3);
  transform: translate3d(-50%, -50%, 0) scale(1.3);
}

.scroll-top {
  width: 45px;
  height: 45px;
  background: #5864FF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.scroll-top:hover {
  color: #fff;
  background: rgba(88, 100, 255, 0.8);
}

@keyframes animation1 {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

@-webkit-keyframes animation1 {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
  50% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  100% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }
}

/*===== All Preloader Style =====*/
.preloader {
  /* Body Overlay */
  position: fixed;
  top: 0;
  left: 0;
  display: table;
  height: 100%;
  width: 100%;
  /* Change Background Color */
  background: #fff;
  z-index: 99999;
}

.preloader .loader {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.preloader .loader .ytp-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 64px;
  margin-left: -32px;
  z-index: 18;
  pointer-events: none;
}

.preloader .loader .ytp-spinner .ytp-spinner-container {
  pointer-events: none;
  position: absolute;
  width: 100%;
  padding-bottom: 100%;
  top: 50%;
  left: 50%;
  margin-top: -50%;
  margin-left: -50%;
  -webkit-animation: ytp-spinner-linspin 1568.2353ms linear infinite;
  animation: ytp-spinner-linspin 1568.2353ms linear infinite;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: ytp-spinner-easespin 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-left {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  right: 50%;
}

.preloader .loader .ytp-spinner .ytp-spinner-container .ytp-spinner-rotator .ytp-spinner-right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  left: 50%;
}

.preloader .loader .ytp-spinner-circle {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  position: absolute;
  width: 200%;
  height: 100%;
  border-style: solid;
  /* Spinner Color */
  border-color: #5864FF #5864FF #ddd;
  border-radius: 50%;
  border-width: 6px;
}

.preloader .loader .ytp-spinner-left .ytp-spinner-circle {
  left: 0;
  right: -100%;
  border-right-color: #ddd;
  -webkit-animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: ytp-spinner-left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

.preloader .loader .ytp-spinner-right .ytp-spinner-circle {
  left: -100%;
  right: 0;
  border-left-color: #ddd;
  -webkit-animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: ytp-right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
}

/* Preloader Animations */
@-webkit-keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ytp-spinner-linspin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@keyframes ytp-spinner-easespin {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg);
  }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg);
  }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg);
  }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg);
  }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg);
  }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg);
  }
}

@-webkit-keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@keyframes ytp-spinner-left-spin {
  0% {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg);
  }
}

@-webkit-keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

@keyframes ytp-right-spin {
  0% {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg);
  }
}

/*===== NAVBAR =====*/
.navbar-area {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky {
  position: fixed;
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky .navbar {
  padding: 10px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .sticky .navbar {
    padding: 15px 0;
  }
}

.sticky .navbar .navbar-nav .nav-item a {
  color: #162447;
}

.sticky .navbar .navbar-nav .nav-item a::before {
  color: #162447;
}

.sticky .navbar .navbar-toggler .toggler-icon {
  background: #162447;
}

.navbar {
  padding: 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 20px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar {
    padding: 17px 0;
  }
}

.navbar-brand {
  padding: 0;
}

.navbar-brand img {
  max-width: 180px;
}

.navbar-toggler {
  padding: 0;
}

.navbar-toggler:focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.navbar-toggler .toggler-icon {
  width: 30px;
  height: 2px;
  background-color: #fff;
  display: block;
  margin: 5px 0;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav {
    padding: 20px 30px;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item {
    margin-left: 0px;
  }
}

.navbar-nav .nav-item a {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 10px 0;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::before {
  content: "";
  position: absolute;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #fdd446;
  bottom: 15px;
  left: 0;
  z-index: -1;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a::before {
    top: auto;
    bottom: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .navbar-nav .nav-item a {
    display: inline-block;
    padding: 6px 0px;
    color: #162447;
  }
}

.navbar-nav .nav-item a:hover::before, .navbar-nav .nav-item a.active::before {
  width: 100%;
}

/* ====== hero-area ===== */
.hero-section {
  position: relative;
  z-index: 1;
  padding: 160px 0 50px;
  background-image: url("../img/hero/hero-bg.svg");
  background-position: top center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .hero-section {
    padding-top: 130px;
  }
}

.hero-section .hero-content {
  margin-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .hero-section .hero-content {
    margin-bottom: 0px;
  }
}

.hero-section .hero-content h1 {
  font-size: 60px;
  margin-bottom: 35px;
  color: #fff;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-section .hero-content h1 {
    font-size: 52px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section .hero-content h1 {
    font-size: 42px;
  }
}

@media (max-width: 767px) {
  .hero-section .hero-content h1 {
    font-size: 32px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .hero-section .hero-content h1 {
    font-size: 42px;
  }
}

.hero-section .hero-content p {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
  color: #fff;
}

@media only screen and (min-width: 1400px) {
  .hero-section .hero-content p {
    padding-right: 120px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-section .hero-content p {
    padding-right: 70px;
  }
}

.hero-section .hero-content .main-btn {
  border-color: #fff;
  color: #fff;
}

.hero-section .hero-content .main-btn::after {
  background: rgba(255, 255, 255, 0.15);
}

.hero-section .hero-content .scroll-bottom {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  border: 3px solid #5864FF;
  color: #5864FF;
  left: 15px;
  bottom: 0;
  z-index: 9;
}

.hero-section .hero-content .scroll-bottom i {
  font-weight: 700;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .hero-section .hero-img {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .hero-section .hero-img img {
    width: 100%;
  }
}

.hero-section .hero-shape .shape {
  position: absolute;
  z-index: -1;
}

.hero-section .hero-shape .shape.shape-1 {
  top: -285px;
  right: -300px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .hero-section .hero-shape .shape.shape-1 {
    top: -420px;
    right: -550px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-section .hero-shape .shape.shape-1 {
    top: -400px;
    right: -550px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-section .hero-shape .shape.shape-1 {
    display: none;
  }
}

@media (max-width: 767px) {
  .hero-section .hero-shape .shape.shape-1 {
    display: none;
  }
}

/* ====== feature css ====== */
@media (max-width: 767px) {
  .feature-section.pt-120 {
    padding-top: 80px;
  }
}

.feature-section .single-feature {
  text-align: center;
  padding: 30px 10px;
}

@media only screen and (min-width: 1400px) {
  .feature-section .single-feature {
    padding: 30px 40px;
  }
}

.feature-section .single-feature .icon {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: rgba(88, 100, 255, 0.1);
  color: #5864FF;
  margin: auto;
  margin-bottom: 30px;
  font-size: 65px;
  line-height: 1;
}

.feature-section .single-feature .content h3 {
  margin-bottom: 20px;
}

.feature-section .single-feature .content p {
  font-size: 18px;
  line-height: 28px;
}

.box-style {
  border-radius: 39px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 59px rgba(214, 214, 214, 0.35);
  box-shadow: 0px 0px 59px rgba(214, 214, 214, 0.35);
  padding: 50px 30px 30px;
  border-bottom: 5px solid;
  text-align: center;
  margin-bottom: 40px;
}

@media only screen and (min-width: 1400px) {
  .box-style {
    padding: 50px 40px 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-style {
    padding: 50px 20px 30px;
  }
}

.box-style .icon {
  width: 85px;
  height: 85px;
  margin: auto;
  margin-bottom: 30px;
  font-size: 70px;
}

.box-style .content h3 {
  margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .box-style .content h3 {
    font-size: 22px;
  }
}

.box-style .content p {
  margin-bottom: 30px;
  font-size: 18px;
  line-height: 26px;
}

.box-style .content a {
  color: #5B657E;
  font-size: 30px;
  font-weight: 700;
}

.box-style .content ul li {
  margin-bottom: 5px;
}

.box-style .content a.main-btn {
  padding: 10px 30px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.box-style .content a.main-btn.border-btn {
  color: #5B657E;
}

.box-style .content a.main-btn.border-btn:hover {
  color: #5864FF;
}

.box-style .content a.main-btn.border-btn:hover::after {
  background: rgba(88, 100, 255, 0.2);
}

.box-style.color-1 {
  border-color: #ff92a5;
}

.box-style.color-1 .icon {
  color: #ff92a5;
}

.box-style.color-1 .content a:hover {
  color: #ff92a5;
}

.box-style.color-2 {
  border-color: #5864FF;
}

.box-style.color-2 .icon {
  color: #5864FF;
}

.box-style.color-2 .content a:hover {
  color: #5864FF;
}

.box-style.color-2 .content .main-btn:hover {
  color: #fff;
}

.box-style.color-3 {
  border-color: #b89cfe;
}

.box-style.color-3 .icon {
  color: #b89cfe;
}

.box-style.color-3 .content a:hover {
  color: #b89cfe;
}

/* ===== about css ====== */
.about-section {
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .about-section.pt-150 {
    padding-top: 100px;
  }
}

.about-section .about-img {
  position: relative;
  z-index: 1;
  padding: 75px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about-section .about-img {
    margin-bottom: 70px;
  }
}

.about-section .about-img .shape {
  position: absolute;
  z-index: -1;
}

.about-section .about-img .shape.shape-1 {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: -70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-img .shape.shape-1 {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .about-section .about-img .shape.shape-1 {
    width: 70%;
  }
}

.about-section .about-img .shape.shape-2 {
  bottom: 0;
  right: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-img .shape.shape-2 {
    width: 75%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-img .shape.shape-2 {
    right: 120px;
  }
}

@media (max-width: 767px) {
  .about-section .about-img .shape.shape-2 {
    bottom: 30px;
  }
}

.about-section .about-img-2 {
  position: relative;
  z-index: 1;
  padding: 75px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .about-section .about-img-2 {
    margin-bottom: 70px;
  }
}

.about-section .about-img-2 .shape {
  position: absolute;
  z-index: -1;
}

.about-section .about-img-2 .shape.shape-1 {
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: -70px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-img-2 .shape.shape-1 {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .about-section .about-img-2 .shape.shape-1 {
    width: 70%;
  }
}

.about-section .about-img-2 .shape.shape-2 {
  bottom: 0;
  left: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .about-section .about-img-2 .shape.shape-2 {
    width: 75%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .about-section .about-img-2 .shape.shape-2 {
    left: 120px;
  }
}

@media (max-width: 767px) {
  .about-section .about-img-2 .shape.shape-2 {
    bottom: 30px;
  }
}

.about-section .about-content ul li {
  padding-left: 40px;
  font-size: 20px;
  font-weight: 600;
  color: #162447;
  margin-bottom: 25px;
  position: relative;
}

.about-section .about-content ul li::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #5864FF;
  position: absolute;
  left: 0;
  top: 6.5px;
  -webkit-box-shadow: 0px 14px 15px rgba(78, 110, 241, 0.26);
  box-shadow: 0px 14px 15px rgba(78, 110, 241, 0.26);
}

/* ====== feature-extended css ====== */
.feature-extended-section .feature-extended-wrapper {
  padding: 70px 0;
  background: rgba(88, 100, 255, 0.1);
}

.feature-extended-section .single-feature-extended {
  padding: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature-extended-section .single-feature-extended {
    padding: 30px 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .feature-extended-section .single-feature-extended {
    padding: 30px 0px;
  }
}

.feature-extended-section .single-feature-extended .icon {
  font-size: 65px;
  line-height: 1;
  margin-bottom: 30px;
  color: #5864FF;
}

.feature-extended-section .single-feature-extended .content h3 {
  margin-bottom: 20px;
}

.feature-extended-section .single-feature-extended .content p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

/* ====== pricing css ====== */
.pricing-section {
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .pricing-section.pt-120 {
    padding-top: 80px;
  }
}

@media (max-width: 767px) {
  .pricing-section.pb-120 {
    padding-bottom: 80px;
  }
}

.pricing-section .pricing-nav-wrapper ul {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pricing-section .pricing-nav-wrapper ul li a {
  font-size: 18px;
  font-weight: 700;
  color: #5B657E;
  padding: 10px 30px;
  background: rgba(88, 100, 255, 0.1);
  margin: 5px;
  border-radius: 30px;
}

.pricing-section .pricing-nav-wrapper ul li a.active {
  color: #fff;
  background: #5864FF;
}

.pricing-section .single-pricing {
  border-radius: 40px;
  background: #fff;
  -webkit-box-shadow: 0px 0px 59px rgba(191, 191, 191, 0.16);
  box-shadow: 0px 0px 59px rgba(191, 191, 191, 0.16);
  padding: 45px 30px;
  margin-bottom: 30px;
}

.pricing-section .single-pricing .pricing-header {
  padding-bottom: 35px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 45px;
  text-align: center;
}

.pricing-section .single-pricing .pricing-header .price {
  font-size: 45px;
  margin-bottom: 30px;
  color: #5864FF;
}

.pricing-section .single-pricing .content {
  margin-bottom: 30px;
}

@media only screen and (min-width: 1400px) {
  .pricing-section .single-pricing .content {
    padding-left: 50px;
  }
}

.pricing-section .single-pricing .content ul li {
  font-size: 18px;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pricing-section .single-pricing .content ul li i {
  margin-right: 15px;
  color: rgba(22, 36, 71, 0.3);
  font-weight: 700;
}

.pricing-section .single-pricing .content ul li i.active {
  color: #5864FF;
}

.pricing-section .single-pricing .pricing-btn {
  text-align: center;
}

.pricing-section .single-pricing .pricing-btn .main-btn {
  padding: 12px 45px;
  font-weight: 400;
}

.pricing-section .single-pricing .pricing-btn .main-btn.border-btn {
  color: #5B657E;
}

.pricing-section .single-pricing .pricing-btn .main-btn.border-btn::after {
  background: rgba(88, 100, 255, 0.1);
}

/* ======= subscribe css ======= */
@media (max-width: 767px) {
  .subscribe-section .section-title h2 {
    font-size: 24px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .subscribe-section .section-title h2 {
    font-size: 32px;
  }
}

.subscribe-section .subscribe-wrapper {
  background-image: url("../img/subscribe/subscribe-bg.svg");
  padding: 65px 50px 50px;
  border-radius: 30px;
}

@media (max-width: 767px) {
  .subscribe-section .subscribe-wrapper {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .subscribe-section .subscribe-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.subscribe-section .subscribe-wrapper .subscribe-form {
  position: relative;
  margin-bottom: 15px;
}

.subscribe-section .subscribe-wrapper .subscribe-form input {
  width: 100%;
  padding: 20px 30px;
  padding-right: 142px;
  border-radius: 18px;
  background: #fff;
  color: #5B657E;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.subscribe-section .subscribe-wrapper .subscribe-form input:focus {
  -webkit-box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.35);
  box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.35);
}

.subscribe-section .subscribe-wrapper .subscribe-form input::-webkit-input-placeholder {
  opacity: 0.7;
  color: #5B657E;
}

.subscribe-section .subscribe-wrapper .subscribe-form input:-ms-input-placeholder {
  opacity: 0.7;
  color: #5B657E;
}

.subscribe-section .subscribe-wrapper .subscribe-form input::-ms-input-placeholder {
  opacity: 0.7;
  color: #5B657E;
}

.subscribe-section .subscribe-wrapper .subscribe-form input::placeholder {
  opacity: 0.7;
  color: #5B657E;
}

.subscribe-section .subscribe-wrapper .subscribe-form button {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 17.5px 25px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 18px;
}

@media (max-width: 767px) {
  .subscribe-section .subscribe-wrapper .subscribe-form button {
    position: static;
    margin-top: 20px;
    background: #fff;
    color: #5864FF;
    padding: 17.5px 35px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .subscribe-section .subscribe-wrapper .subscribe-form button {
    position: absolute;
    margin-top: 0px;
    color: #fff;
    background: #5864FF;
    padding: 17.5px 25px;
  }
}

/* ======== testimonial css ======= */
.testimonial-section {
  padding: 170px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-section {
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .testimonial-section {
    padding: 30px 0 100px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .testimonial-section {
    padding: 0px;
  }
}

.testimonial-section .testimonial-active-wrapper {
  position: relative;
}

.testimonial-section .testimonial-active-wrapper .shapes .shape {
  position: absolute;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .testimonial-section .testimonial-active-wrapper .shapes .shape {
    display: none;
  }
}

.testimonial-section .testimonial-active-wrapper .shapes .shape.shape-1 {
  left: -100px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-section .testimonial-active-wrapper .shapes .shape.shape-1 {
    width: 44%;
  }
}

.testimonial-section .testimonial-active-wrapper .shapes .shape.shape-2 {
  left: 150px;
  bottom: -110px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-section .testimonial-active-wrapper .shapes .shape.shape-2 {
    width: 25%;
    bottom: -50px;
  }
}

.testimonial-section .testimonial-active-wrapper .tns-controls {
  position: absolute;
  right: 175px;
  bottom: 0px;
  z-index: 9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-section .testimonial-active-wrapper .tns-controls {
    right: 0;
  }
}

@media (max-width: 767px) {
  .testimonial-section .testimonial-active-wrapper .tns-controls {
    right: 0;
    left: 0;
    bottom: -75px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .testimonial-section .testimonial-active-wrapper .tns-controls {
    right: 0;
    left: auto;
    bottom: 0px;
  }
}

.testimonial-section .testimonial-active-wrapper .tns-controls button {
  border: 2px solid #c7cad3;
  color: #c7cad3;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0 8px;
  -webkit-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background: transparent;
}

.testimonial-section .testimonial-active-wrapper .tns-controls button i {
  font-weight: 700;
}

.testimonial-section .testimonial-active-wrapper .tns-controls button:hover {
  color: #5864FF;
  border-color: #5864FF;
  -webkit-box-shadow: 0px 6px 16px rgba(88, 100, 255, 0.25);
  box-shadow: 0px 6px 16px rgba(88, 100, 255, 0.25);
}

.testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .testimonial-img {
  text-align: right;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .testimonial-img {
    text-align: left;
    display: inline-block;
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .testimonial-img {
    text-align: left;
    display: inline-block;
    margin-bottom: 30px;
  }
}

.testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .testimonial-img img {
  max-width: 100%;
}

.testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .testimonial-img .quote {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 10px solid #fff;
  background: #5864FF;
  font-size: 40px;
  line-height: 1;
  color: #fff;
  -webkit-box-shadow: 0px 0px 26px rgba(155, 155, 155, 0.16);
  box-shadow: 0px 0px 26px rgba(155, 155, 155, 0.16);
  position: absolute;
  top: 50%;
  right: -50px;
  -webkit-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}

.testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .content-wrapper .content p {
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
}

.testimonial-section .testimonial-active-wrapper .testimonial-active .single-testimonial .content-wrapper .info h4 {
  margin-bottom: 10px;
}

/* ======= footer css ======= */
.footer {
  background-image: url("../img/footer/footer-bg.svg");
  background-size: cover;
  background-position: top right;
  padding-top: 260px;
  margin-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer {
    padding-top: 250px;
  }
}

@media (max-width: 767px) {
  .footer {
    padding-top: 120px;
    background: #5864FF;
  }
}

.footer .widget-wrapper .footer-widget {
  margin-bottom: 40px;
}

@media only screen and (min-width: 1400px) {
  .footer .widget-wrapper .footer-widget .desc {
    padding-right: 100px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
  .footer .widget-wrapper .footer-widget .desc {
    padding-right: 50px;
  }
}

.footer .widget-wrapper .footer-widget .socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer .widget-wrapper .footer-widget .socials li {
  margin-right: 20px;
}

.footer .widget-wrapper .footer-widget .socials li:last-child {
  margin-right: 0;
}

.footer .widget-wrapper .footer-widget .socials li a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.13);
  color: #fff;
}

.footer .widget-wrapper .footer-widget .socials li a:hover {
  color: #5864FF;
  background: #fff;
}

.footer .widget-wrapper .footer-widget h3 {
  margin-bottom: 25px;
  color: #fff;
}

.footer .widget-wrapper .footer-widget ul.links li {
  font-size: 18px;
  line-height: 34px;
}

.footer .widget-wrapper .footer-widget ul.links li a {
  color: #fff;
}

.footer .widget-wrapper .footer-widget ul.links li a:hover {
  padding-left: 8px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-35 {
  margin-top: 35px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-45 {
  margin-top: 45px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-55 {
  margin-top: 55px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-65 {
  margin-top: 65px;
}

.mt-70 {
  margin-top: 70px;
}

.mt-75 {
  margin-top: 75px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-85 {
  margin-top: 85px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-95 {
  margin-top: 95px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-105 {
  margin-top: 105px;
}

.mt-110 {
  margin-top: 110px;
}

.mt-115 {
  margin-top: 115px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-125 {
  margin-top: 125px;
}

.mt-130 {
  margin-top: 130px;
}

.mt-135 {
  margin-top: 135px;
}

.mt-140 {
  margin-top: 140px;
}

.mt-145 {
  margin-top: 145px;
}

.mt-150 {
  margin-top: 150px;
}

.mt-155 {
  margin-top: 155px;
}

.mt-160 {
  margin-top: 160px;
}

.mt-165 {
  margin-top: 165px;
}

.mt-170 {
  margin-top: 170px;
}

.mt-175 {
  margin-top: 175px;
}

.mt-180 {
  margin-top: 180px;
}

.mt-185 {
  margin-top: 185px;
}

.mt-190 {
  margin-top: 190px;
}

.mt-195 {
  margin-top: 195px;
}

.mt-200 {
  margin-top: 200px;
}

.mt-205 {
  margin-top: 205px;
}

.mt-210 {
  margin-top: 210px;
}

.mt-215 {
  margin-top: 215px;
}

.mt-220 {
  margin-top: 220px;
}

.mt-225 {
  margin-top: 225px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-35 {
  margin-bottom: 35px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-55 {
  margin-bottom: 55px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-65 {
  margin-bottom: 65px;
}

.mb-70 {
  margin-bottom: 70px;
}

.mb-75 {
  margin-bottom: 75px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mb-85 {
  margin-bottom: 85px;
}

.mb-90 {
  margin-bottom: 90px;
}

.mb-95 {
  margin-bottom: 95px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-105 {
  margin-bottom: 105px;
}

.mb-110 {
  margin-bottom: 110px;
}

.mb-115 {
  margin-bottom: 115px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-125 {
  margin-bottom: 125px;
}

.mb-130 {
  margin-bottom: 130px;
}

.mb-135 {
  margin-bottom: 135px;
}

.mb-140 {
  margin-bottom: 140px;
}

.mb-145 {
  margin-bottom: 145px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-155 {
  margin-bottom: 155px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mb-165 {
  margin-bottom: 165px;
}

.mb-170 {
  margin-bottom: 170px;
}

.mb-175 {
  margin-bottom: 175px;
}

.mb-180 {
  margin-bottom: 180px;
}

.mb-185 {
  margin-bottom: 185px;
}

.mb-190 {
  margin-bottom: 190px;
}

.mb-195 {
  margin-bottom: 195px;
}

.mb-200 {
  margin-bottom: 200px;
}

.mb-205 {
  margin-bottom: 205px;
}

.mb-210 {
  margin-bottom: 210px;
}

.mb-215 {
  margin-bottom: 215px;
}

.mb-220 {
  margin-bottom: 220px;
}

.mb-225 {
  margin-bottom: 225px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-15 {
  padding-top: 15px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-35 {
  padding-top: 35px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-45 {
  padding-top: 45px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-55 {
  padding-top: 55px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-65 {
  padding-top: 65px;
}

.pt-70 {
  padding-top: 70px;
}

.pt-75 {
  padding-top: 75px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-85 {
  padding-top: 85px;
}

.pt-90 {
  padding-top: 90px;
}

.pt-95 {
  padding-top: 95px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-105 {
  padding-top: 105px;
}

.pt-110 {
  padding-top: 110px;
}

.pt-115 {
  padding-top: 115px;
}

.pt-120 {
  padding-top: 120px;
}

.pt-125 {
  padding-top: 125px;
}

.pt-130 {
  padding-top: 130px;
}

.pt-135 {
  padding-top: 135px;
}

.pt-140 {
  padding-top: 140px;
}

.pt-145 {
  padding-top: 145px;
}

.pt-150 {
  padding-top: 150px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-160 {
  padding-top: 160px;
}

.pt-165 {
  padding-top: 165px;
}

.pt-170 {
  padding-top: 170px;
}

.pt-175 {
  padding-top: 175px;
}

.pt-180 {
  padding-top: 180px;
}

.pt-185 {
  padding-top: 185px;
}

.pt-190 {
  padding-top: 190px;
}

.pt-195 {
  padding-top: 195px;
}

.pt-200 {
  padding-top: 200px;
}

.pt-205 {
  padding-top: 205px;
}

.pt-210 {
  padding-top: 210px;
}

.pt-215 {
  padding-top: 215px;
}

.pt-220 {
  padding-top: 220px;
}

.pt-225 {
  padding-top: 225px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pb-125 {
  padding-bottom: 125px;
}

.pb-130 {
  padding-bottom: 130px;
}

.pb-135 {
  padding-bottom: 135px;
}

.pb-140 {
  padding-bottom: 140px;
}

.pb-145 {
  padding-bottom: 145px;
}

.pb-150 {
  padding-bottom: 150px;
}

.pb-155 {
  padding-bottom: 155px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pb-165 {
  padding-bottom: 165px;
}

.pb-170 {
  padding-bottom: 170px;
}

.pb-175 {
  padding-bottom: 175px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pb-185 {
  padding-bottom: 185px;
}

.pb-190 {
  padding-bottom: 190px;
}

.pb-195 {
  padding-bottom: 195px;
}

.pb-200 {
  padding-bottom: 200px;
}

.pb-205 {
  padding-bottom: 205px;
}

.pb-210 {
  padding-bottom: 210px;
}

.pb-215 {
  padding-bottom: 215px;
}

.pb-220 {
  padding-bottom: 220px;
}

.pb-225 {
  padding-bottom: 225px;
}
